import React, { useReducer, useContext, useEffect } from 'react'
import { lockedReducer } from '../Utils/reducers';
import { api } from '../../services/api';
import ColorButton from '../Buttons/ColorButton';
import DefaultModal from '../Utils/DefaultModal';
import toast from 'react-hot-toast'
import { Box, Tooltip, TextField, Stepper, Step, StepLabel} from '@mui/material'
import validateData from './validateDataImportacao';
import validateDataNext from './validateNext'
import { externalComponent } from '../AppRoutes';
import MegaAutoComplete from '../Utils/megautocomplete';
import { number2dayweek } from '../Utils/dayjsptbrhelper';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import apptheme from '../theme/default';
import dayjs from 'dayjs';
import { getSession } from '../../contexts/auth';
import Typography from '@mui/material/Typography';
import PreboardingDocuments from './documents';
import InvoiceModal from './invoiceModal';
import InputFileUpload from './attachment';
import { NumbersOnly } from '../Utils/IntegerOnly';

const url = '/importacao/'

const name_list = {
    0: 'Importação',
    1: 'Pré-Embarque'
}

const submit = async (data, getData, setLoader, setState, handleClose) => {

    setLoader(true)
        let res = null

        if (data.id)
            res = await api.patch(`${url}${data.id}/`, data)
        else
            res = await api.post(url, data)
        if (res.status === 201 || res.status === 200) {
            toast.success(`Importação ${res.status === 201 ? 'adicionado' : 'editado'} com sucesso`)
            getData()
            loadRetrieve(res.data.id,setState,setLoader,url)
            setLoader(false)
            setState({saved:true})

            if(data.status === 2){
                handleClose()
                appllyInvoice(data, setLoader)
            }

            return
        }
        toast.error(`Importação não pôde ser ${!data.id ? 'adicionado' : 'editado.'} Motivo: ${res.data}`)
    setLoader(false)
        
}

const appllyInvoice = async (data, setLoader) => {
    setLoader(true)
    let res = await api.post(`${url}apply_invoice/`, data)
    setLoader(false)
}

const updateStatus = async (data, setState, type) => {
    let value = null

    if(type === true){
        value = data.step + 1
    } else if (type === false){
        value = data.step - 1
    }
    

    if(value === 1){
        setState({status:2, step:value})
    }

    if(value === 0){
        setState({status:1, step:value})
    }

}


const loadRetrieve = async (id, setState, setLoader, url) => {
    setLoader(true)
    const res = await api.get(url + `${id}/`)
    if (res.status === 200) {
        setState({ ...res.data})
    }

    const resInvoice = await api.get(url + `${id}/consult_file/`)
    if (resInvoice.status === 200) {
        setState({invoice:resInvoice.data})
    }
    setLoader(false)
}

const getTitle = (data) => {
    if (data?.id)
        return 'Editar'
    return 'Adicionar'
}

const generateSteps = (state, setState, setLoader, reloadData) => {

    return [
        {
            label: 'Importação', component: (
                <Box className='item-content-modal noborder' >

                    <Box className='item-profile-row' sx={{alignItems:'flex-end'}}>

                        <MegaAutoComplete
                                value={state.exporter}
                                onChange={(data) => setState({ exporter : data })}
                                noOptionsText={'Digite para procurar um Exportador'}
                                timeoutUrl='/exportador/exportadores/'
                                timeoutParam='label'
                                label='Exportador'
                        />

                        <MegaAutoComplete
                                value={state.fabricator}
                                onChange={(data) => setState({ fabricator : data })}
                                noOptionsText={'Digite para procurar um Fabricante'}
                                timeoutUrl='/fabricante/fabricadores/'
                                timeoutParam='label'
                                label='Fabricante'
                        />

                    </Box>

                    <Box className='item-profile-row'>

                        <TextField
                                id="num_pi"
                                type="text"
                                label="Número PI"
                                value={state.num_pi}
                                onChange={(e) => { setState({ 'num_pi': e.target.value }) }}
                                fullWidth={false}
                                size='small'
                                inputProps={{
                                    maxLength: 50
                                }}
                        />

                    
                        <DatePicker 
                            slotProps={{ textField: { size: 'small' } }} 
                            label="ETD:"
                            size="small"
                            value={dayjs(state.etd)}  
                            onChange={(e) => { setState({ 'etd': e.format('YYYY-MM-DD') }) }}  
                            format='DD/MM/YYYY'
                            dayOfWeekFormatter={(n,date) => number2dayweek[date.$W]}
                            ampm={false}
                        />
                        
                    </Box>
                    
                </Box>
            ),
        },
        {   
            label: 'Pré-Embarque', component: (
                <Box className='item-content-modal noborder'>

                    <Box className='item-profile-row'>

                        <MegaAutoComplete
                                key='importer'
                                value={state.importer}
                                onChange={(data) => setState({ importer : data })}
                                noOptionsText={'Digite para procurar um Importador'}
                                timeoutUrl='/importador/complete/'
                                timeoutParam='label'
                                label='Importador'
                        />

                    </Box>

                    <Box className='item-profile-row'>

                        <MegaAutoComplete
                                value={state.shippingagent}
                                onChange={(data) => setState({ shippingagent : data })}
                                noOptionsText={'Digite para procurar um Agente'}
                                timeoutUrl='/agente_maritimo/complete/'
                                timeoutParam='label'
                                label='Agente Marítimo'
                        />

                    </Box>

                    <Box className='item-profile-row'>

                        <MegaAutoComplete
                            value={state.armador}
                            onChange={(data) => setState({ armador : data })}
                            noOptionsText={'Digite para procurar um Armador'}
                            timeoutUrl='/armador/complete/'
                            timeoutParam='label'
                            label='Armador'
                        />  

                    </Box>

                    <Box className='item-profile-row'>

                        <TextField
                            id="booking"
                            type="text"
                            label="Booking"
                            value={state.booking}
                            onChange={(e) => { setState({ 'booking': e.target.value }) }}
                            fullWidth={false}
                            size='small'
                            inputProps={{
                                maxLength: 50
                            }}
                        />

                    </Box>

                    <Box className='item-profile-row'>

                        <MegaAutoComplete
                            value={state.pol}
                            onChange={(data) => setState({ pol : data })}
                            noOptionsText={'Digite para procurar um Porto'}
                            timeoutUrl='/porto/complete/'
                            timeoutParam='label'
                            label='POL'
                        />

                        <MegaAutoComplete
                            value={state.pod}
                            onChange={(data) => setState({ pod : data })}
                            noOptionsText={'Digite para procurar um Porto'}
                            timeoutUrl='/porto/complete/'
                            timeoutParam='label'
                            label='POD'
                        />

                    </Box>

                  
                    <Box className='item-profile-row'>

                        <DatePicker 
                            slotProps={{ textField: { size: 'small' } }} 
                            label="ETA:"
                            size="small"
                            value={dayjs(state.eta)}  
                            onChange={(e) => {setState({ 'eta': e?.format('YYYY-MM-DD') ?? '' })}}
                            format='DD/MM/YYYY'
                            dayOfWeekFormatter={(n,date) => number2dayweek[date.$W]}
                            ampm={false}
                        />

                        <DatePicker 
                            slotProps={{ textField: { size: 'small' } }} 
                            label="ETS:"
                            size="small"
                            value={dayjs(state.ets)}  
                            onChange={(e) => {setState({ 'ets': e?.format('YYYY-MM-DD') ?? '' })}} 
                            format='DD/MM/YYYY'
                            dayOfWeekFormatter={(n,date) => number2dayweek[date.$W]}
                            ampm={false}
                        />

                    </Box>

                    <Box className='item-profile-row'>

                        <TextField
                            id="frete"
                            type="text"
                            label="Frete"
                            value={state.frete}
                            onChange={(e) => { setState({ 'frete': NumbersOnly(e.target.value) }) }}
                            fullWidth={false}
                            size='small'
                            inputProps={{
                                maxLength: 50
                            }}
                        />

                        <InputFileUpload id={state.id} setState={setState}/>

                    </Box>

                    <Box sx={{display:'flex',flexDirection:'column'}} className='item-profile-row'>

                        {state.invoice.length === 0 ? (
                            
                            <Box> 
                                <Typography>Pedidos:</Typography>
                                <br/>
                                <PreboardingDocuments state={state} setState={setState} />
                            </Box>
                        ) : (
                          
                        <InvoiceModal
                            invoice={state.invoice}
                            data={state}
                            setLoader={setLoader}
                            reloadData={reloadData}
                            setPreviousState={setState}
                        />
                        

                        )}
                        
                    </Box>

                </Box>
            )
        }
    ]
}

const EditImportacao = ( {data, handleClose, reloadData} ) => {

    const user = getSession()

    const { setLoader } = useContext(externalComponent)

    const [state, setState] = useReducer(lockedReducer, {
        exporter: '',
        fabricator:'',
        num_pi: '',
        etd:'',
        status:1,
        
        importer: {'id':'', 'label':''},
        shippingagent: '',
        armador: '',
        booking: '',
        pol: '',
        pod: '',
        eta: null,
        ets: null,
        frete: 0,
        documents:[],

        invoice:[],
        file:null,
        invoiceModal:null,

        deleteRow:null,

        step: 0,
        saved:false,
        ...data,
        id: data.id ? data.id : 0,
        id_preboarding: data.id_preboarding ? data.id_preboarding : 0
    })

    const validData = validateData(state)
    const validNext = validateDataNext(state)

    useEffect(() => {
        
        if (data.id) {
            setState({saved:true})
            loadRetrieve(data.id, setState, setLoader, url)
        }

            
        else{
            setState({ loaded: true })
        }

            
    }, [setLoader, data.id, data.id_preboarding])


    const stepsArray = generateSteps(state, setState, setLoader, reloadData)
    const maxStep = stepsArray.length - 1
    
    return(
        <>

        <DefaultModal
                title={
                    `${getTitle(state)} ${name_list[state.step]}`
                }
                handleClose={handleClose}
                content={
                    <>
                        <Stepper activeStep={state.step}>
                            {stepsArray.map((step, index) => (
                                <Step key={step.label} >
                                    <StepLabel sx={{
                                        '.MuiStepIcon-root.Mui-active': { color: apptheme.primaryColor },
                                        '.MuiStepIcon-root.Mui-completed': { color: apptheme.primaryColor }
                                    }}>
                                        {step.label}
                                    </StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <Box className='item-content-modal noborder' sx={{ paddingTop: '1rem' }}>
                            {stepsArray[state.step].component}
                        </Box>
                    </>
                }
                action={
                    <>
                    {
                       
                        <Tooltip placement="left" title={validData}>
                            <Box>
                                <ColorButton
                                    onClick={() => submit(state, reloadData, setLoader, setState, handleClose)}
                                    disabled={validData}
                                >Salvar
                                </ColorButton>
                            </Box>
                        </Tooltip>

                    }

                    {
                        (state.step !== 0) && 
                        <Box sx={{marginLeft:'0!important'}}>
                            <ColorButton
                                onClick={() => { updateStatus(state, setState, false);}}
                                >
                                    Anterior
                            </ColorButton>
                        </Box>
                    }

                    
                    {
                        state.step !== maxStep && 
                            <Tooltip placement="left" title={validNext}>
                                <Box sx={{marginLeft:'0!important'}}>
                                    <ColorButton

                                            onClick={() => { updateStatus(state, setState, true );}}
                                            disabled={validNext}>
                                                Avançar
                                    </ColorButton>
                                </Box>
                            </Tooltip>
                    }   
                    </>
                }
            />
        </>
    )
}

export default EditImportacao;