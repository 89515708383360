import React, { useContext, useMemo, useReducer, useEffect } from 'react'
import { Box, IconButton } from '@mui/material'
import { api } from '../../../services/api';
import toast from 'react-hot-toast'
import { getSession } from '../../../contexts/auth';
import { lockedReducer } from '../../Utils/reducers';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ConfirmationDialog from '../../Utils/ConfirmationDialog';
import { externalComponent } from '../../AppRoutes';
import Table, { TableHook } from '../../Table';
import EditFrete from './edit';
import PedidoDocumentos from './indexfiles';
import dayjs from 'dayjs';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ColorButton from '../../Buttons/ColorButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';


const url = '/painel_pedido/'

const submitDelete = async (data, setState, getData, setLoader) => {
    setLoader(true)
    const res = await api.delete(`${url}${data.id}/`)
    setLoader(false)
    if (res.status === 204) {
        toast.success('Deletado com sucesso')
        setState({ deleteRow: false })
        getData()
        return
    }
    console.error('Falha ao deletar')

}

export const loadData = async (setState, permission, setLoader) => {
    if (!permission) {
        toast.error('Você não tem permissão para ver os Pedidos')
        return
    }

    setLoader(true)
    const res = await api.get(`${url}`)
    if (res.status === 200) {
        setState({rows:res.data.results})
    }
    setLoader(false)

}



const PainelPedido = () => {

    const { setLoader } = useContext(externalComponent)

    const user = getSession()

    const [state, setState] = useReducer(lockedReducer, {
        rows: [],
        editRow: null,
        deleteRow: null,
        fileRow: null,
        unitys: []
    })

    useEffect(() => {
        loadData(setState, user.permissions.view_order, setLoader)
    }, [setLoader, user.permissions.view_order])

    const [table, reloadTable] = TableHook()

    const reloadData = () => {
        loadData(setState, user.permissions.view_order, setLoader)
        reloadTable()
    }

    return(
        <>
         {state.deleteRow &&
                <ConfirmationDialog
                    content={`Deseja excluir o pedido ${state.deleteRow.identifier}?`}
                    handleClose={() => setState({ deleteRow: null })}
                    onConfirm={() => submitDelete(state.deleteRow, setState, reloadData, setLoader)}
                />
         }
        {state.editRow &&
                <EditFrete
                    data={state.editRow}
                    handleClose={() => setState({ editRow: null })}
                    reloadData={reloadData}
                />
        }

        {state.fileRow &&
                <PedidoDocumentos
                    data={state.fileRow}
                    handleClose={() => setState({ fileRow: null })}
                    reloadData={reloadData}
                />
        }

         <Box className='information-table'>
                <Box className='user-table'>

                    <Card sx={{height:'100%',overflow: 'hidden'}}>
                    <CardContent sx={{ height: '100%', overflowY: 'auto' }}>

                        <Box>
                            <ColorButton startIcon={ <AddCircleOutlineIcon/>} reverse={true} sx={{marginBottom:'1rem'}} 
                                onClick={() => user.permissions.add_order ? setState({ editRow: {} }) : null}    
                                disabled={user.permissions.add_order ? false : true}
                            >
                                Adicionar
                            </ColorButton>
                        </Box>
                       
                        
                        <Box >
                        {
                            state.rows?.map((document, index) => (

                            <Accordion key={index}>
                                <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
                                  
                                    <Box sx={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between',width:'100%'}}>

                                        <Box sx={{display:'flex', flexDirection:'row',gap:'2rem'}}>
                                            <Box sx={{display:'flex', flexDirection:'column'}}>
                                                <h6>Data pedido</h6>
                                                <Typography>{dayjs(document.order_date).format('MM/YYYY')}</Typography>
                                            </Box>

                                            <Box sx={{display:'flex', flexDirection:'column'}}>
                                                <h6>Qtde de Docs</h6>
                                                <Typography>{document.total_file}</Typography>
                                            </Box>

                                            <Box sx={{display:'flex', flexDirection:'column'}}>
                                                <h6>Exportador</h6>
                                                <Typography>{document.exporter_name}</Typography>
                                            </Box>

                                            <Box sx={{display:'flex', flexDirection:'column'}}>
                                                <h6>Observação</h6>
                                                <Typography>{document.observation}</Typography>
                                            </Box>
                                        </Box>
                                        

                                        <Box >
                                            {user.permissions.change_order && <IconButton onClick={(event) => { setState({ editRow: document });event.stopPropagation(); }}><EditIcon /></IconButton>}
                                            {user.permissions.delete_order && <IconButton onClick={(event) => { setState({ deleteRow: document });event.stopPropagation(); }}><DeleteIcon /></IconButton>}
                                        </Box>

                                        
                                    </Box>

                                </AccordionSummary>
                                <AccordionDetails sx={{ display: 'block', padding: 2 }}>

                                    <PedidoDocumentos
                                        data={document}
                                        reloadDataOrder={reloadData}
                                        key={index}
                                    />

                                </AccordionDetails>
                            </Accordion>


                            ))
                        }
                        </Box>

                        </CardContent>
                        </Card>
                        
                </Box>
            </Box>
        </>
    )
}

export default PainelPedido;