import React, {useContext, useState, useEffect} from 'react';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { api } from '../../services/api';
import { externalComponent } from '../AppRoutes';
import { Box, Tooltip} from '@mui/material'
import toast from 'react-hot-toast';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteIcon from '@mui/icons-material/Delete';
import { mediaURL } from '../../services/api';

const url = '/importacao/'

const IconButton = styled('label')({
    cursor: 'pointer',
    display: 'inline-block',
  });
  
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

 const types_allowed = ['image/jpeg','image/png','application/pdf']
  
 const InputFileUpload = ({id, setState}) => {

    const {setLoader} = useContext(externalComponent)
    const [file, setFile] = useState('')

    const flushFiles = (e) => {
      e.target.value = '';
    }

    const handleFileChange = async (e) => {
      if (e.target.files) {
        const files = e.target.files[0]
     
          if(types_allowed.includes(files.type)){

            const fileSizeInMB = files.size / (1024 * 1024);
            const size_allowed = 600000
            
            if (fileSizeInMB > size_allowed) {
              toast.error(`O arquivo ${files.name} é grande demais !`)
            }else{
              uploadFiles(files);
              setFile(true)
              setState({file:true})
            }
          }
          else{
              toast.error(`Arquivo ${files.name} contém extensão não permitida pelo sistema !`)
          }
        
      }
    };
  
    const uploadFiles = async (file) => {

        setLoader(true)
            const formData = new FormData();
            formData.append('file', file);
            formData.append('id', id);
            const res = await api.post(`${url}uploadorderfile/`,formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
            })
            if (res.status === 200){
                toast.success('Arquivo anexado com sucesso !')
            }
        setLoader(false)
       

    }

    const openFile = async () => {
        const fileURL = mediaURL + file
        window.open(fileURL, '_blank');
    }

    const getFile = async () => {
        
        setLoader(true)
        const res = await api.get(`${url}servefile/${id}/`)
        if (res.status === 200){
            setFile(res.data.filename)
        }
        setLoader(false)

    }

    const deleteFile = async () => {
        setLoader(true)
        const body = {
            id: id
        }
        const res = await api.post(`${url}deletefile/`, body)
        if (res.status === 200){
            getFile()
            setState({file:null})
        }
        setLoader(false)
    }

    useEffect(() => {
        getFile()
    }, [file])


    return (
        <>
        {
            file ?
            
            <Box sx={{display:'flex',alignItems:'center'}}>
                <IconButton>
                    <Tooltip title='Visualiza um arquivo'>
                        <AttachFileIcon  className='actionIcon'  />
                    </Tooltip>
                    <VisuallyHiddenInput type="file" onClick={openFile} />
                </IconButton>

                <IconButton onClick={deleteFile}>
                    <DeleteIcon className='actionIcon' />
                </IconButton>
            </Box>

          :
            
            
            <Box sx={{display:'flex',alignItems:'center'}}>
                <IconButton>
                    <Tooltip title='Insere um arquivo'>
                        <CloudUploadIcon  className='actionIcon'  />
                    </Tooltip>
                    <VisuallyHiddenInput type="file" onClick={flushFiles} onChange={handleFileChange}/>
                </IconButton>
            </Box>
           

        }
        </>
  
    );
  }

export default  InputFileUpload;