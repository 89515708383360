import React from 'react'
import { DataGrid } from '@mui/x-data-grid';
import { Box } from '@mui/material'
import DefaultModal from '../../Utils/DefaultModal';


const columns = [
        { field: 'product', headerName: 'Código', flex: 0.2, filterType: 'string' },
        { field: 'description', headerName: 'Descrição', flex: 0.5, filterType: 'string' },
        { field: 'amount', headerName: 'Quantidade', flex: 0.3, filterType: 'number' },
        { field: 'balance', headerName: 'Balance', flex: 0.2, filterType: 'number',
            renderCell: (props) => (
                <Box>{props.row.amount - (props.row.invoice === null ? props.row.invoice : 0)}</Box>
            )
         },
        { field: 'price', headerName: 'Preço', flex: 0.3, filterType: 'number',
            renderCell: ({value}) => `$${value}`
        }
    ]


const ModalLog = ({data,handleClose}) => {
    return (
        <>
          <DefaultModal
                title={
                    `Lista Produto ${data.id}`
                }
                dialogProps={{
                    sx:{'& .MuiPaper-root': {
                        maxWidth:'unset',
                        maxHeight:'unset',
                        width:'65%',
                        height:'65%',
                        margin:'0px'
                    }}
                }}
                handleClose={handleClose}
                content={
                    <Box>
                        <DataGrid 
                    
                                rows={data.log}                                                                                                                                                                                                                                                                                                                                                                                                                       
                                columns={columns}
           
                        />
                    </Box>

                }


            />
        
        </>
    )
}

export default ModalLog;