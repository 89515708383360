import React, { useState } from 'react'
import { Box, IconButton, Tooltip} from '@mui/material'
import dayjs from 'dayjs';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ModalProduct from './modalProduct';

const PreboardingDocuments = ({state, setState}) => {

    const [modalProduct, setModalProduct] = useState(null)

    return(
        <>

        {
            modalProduct &&
            <ModalProduct
            data={modalProduct}
            importacao={state}
            setInvoice={setState}
            handleClose={() => setModalProduct(null)}
            />
        }


        {
            state.documents?.map((document, index) => (
            <Accordion key={index}>
                <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
                    <Typography>{dayjs(document.order_date).format('MM/YYYY')}</Typography>
                </AccordionSummary>
                    <AccordionDetails>


                        {/* Documentos */}
                        <Typography>Documentos:</Typography>
                        <br/>
                        <Box sx={{display:'flex', flexDirection:'column',gap:'1rem'}}>
                        {
                            
                            document.orderfile_set?.map((file, index) => (
                                <Card>
                                    <CardContent>
                                        <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                                            <Typography>{file.name} - {file.number} - {file.type} - Total de Pneus: {file.total_amount}</Typography>
                                            
                                            <Tooltip title='Visualiza os produtos deste documento'>
                                                <IconButton onClick={() => setModalProduct(file)}><VisibilityIcon/></IconButton> 
                                            </Tooltip>
                                            
                                        </Box>
                                    </CardContent>
                                </Card>
                                
                                ))
                        }
                        </Box>


                    </AccordionDetails>
            </Accordion>
            ))
        }
        
        </>
    )
}

export default PreboardingDocuments;