import React, { useContext, useMemo, useReducer, useEffect } from 'react'
import { Box, IconButton } from '@mui/material'
import { api } from '../../../services/api';
import toast from 'react-hot-toast'
import { getSession } from '../../../contexts/auth';
import { lockedReducer } from '../../Utils/reducers';
import ModalProduct from '../../Utils/modalProduct';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ConfirmationDialog from '../../Utils/ConfirmationDialog';
import { externalComponent } from '../../AppRoutes';
import Table, { TableHook } from '../../Table';
import EditProducts from './editproducts';
import ColorButton from '../../Buttons/ColorButton';
import clsx from 'clsx';
import Tooltip from '@mui/material/Tooltip';

const url = '/documento_produto/'

const submitDelete = async (data, setState, getData, setLoader) => {
    setLoader(true)
    const res = await api.delete(`${url}${data.id}/`)
    setLoader(false)
    if (res.status === 204) {
        toast.success('Deletado com sucesso')
        setState({ deleteRow: false })
        getData()
        return
    }
    console.error('Falha ao deletar')

}

const registerLog = async (data,setLoader,setState) => {
    setLoader(true)
    const res = await api.post(`/produto_log/${data.id}/register/`)
    setLoader(false)
    if (res.status === 200) {
        toast.success('Log salvo com sucesso')
        setState({ logRow: false })
        getLogs(data,setLoader,setState)
    }
}

const getLogs = async (data,setLoader,setState) => {
    setLoader(true)
    const res = await api.get(`/produto_log/${data.id}/consult/`)
    setLoader(false)
    if (res.status === 200) {
        setState({ logs: res.data })
    }
}

const compareAmount = (row,log) => {
    const amount = row.amount
    const product = row.product
    
    if(log.length !== 0){
        if(log[0]['log'].filter(each => each.product === product).length !== 0){
            const logAmount = log[0]['log'].filter(each => each.product === product)[0].amount
            if(logAmount > amount){
                let value = logAmount - amount
                return [amount,`(-${value})`,'red']
            }else if(logAmount < amount){
                let value  = amount - logAmount
                return [amount,`(+${value})`,'green']
            }else if(logAmount === amount){
                return [amount]
            }

        }
    }
    
    return [amount]
}

const comparePrice = (row,log) => {

    const price = row.price
    const product = row.product

    if(log.length !== 0){
        if(log[0]['log'].filter(each => each.product === product).length !== 0){
            const logPrice = log[0]['log'].filter(each => each.product === product)[0].price
            if(logPrice > price){
                let value = logPrice - price
                return [price,`(-${value})`,'red']
            }else if(logPrice < price){
                let value  = price - logPrice
                return [price,`(+${value})`,'green']
            }else if(logPrice === price){
                return [price]
            }

            }
    }
    
    return [price]

}

const createColor = (rows,logs) => {

    if(logs.length !== 0){
        for (let i = 0; i < rows.length; i++){
            const each = rows[i]
            const product = each.product
            const logRow = logs[0]['log'].filter(each => each.product === product).length
                if(logRow === 0){
                    each.color = 'green'
                    each.tooltip = 'Produto adicionado em comparação com o primeiro documento.'
                }
        }
    }

}

const deletedRow = (rows,logs,setState) => {
    
    if(logs.length !== 0){
        const log = logs[0].log
        for  (let i = 0; i < log.length; i++){
            const each = log[i]
            const product = each.product

            const logRow = rows.filter(each => each.product === product).length
            if(logRow === 0){
                each.color = 'red'
                each.tooltip = 'Produto deletado em comparação com o primeiro documento.'
                setState({rows:[...rows,each]})
            }

        }
    }

}

export const loadData = async (setState, permission, setLoader) => {
    if (!permission) {
        toast.error('Você não tem permissão para ver os Fretes')
        return
    }
}

const ProdutoDocumento = ({data,handleClose}) => {

    const { setLoader } = useContext(externalComponent)

    const user = getSession()

    const [state, setState] = useReducer(lockedReducer, {
        rows: [],
        editRow: null,
        deleteRow: null,
        logRow:null,
        logs: [],
        unitys: []
    })

    const columns = (setState, permissions) => {
        return [
            { field: 'product', headerName: 'Código', flex: 0.1, filterType: 'string',
                renderCell: (props) => (<Tooltip title={props.row.tooltip}><Box>{props.row.product}</Box></Tooltip>),
                cellClassName: (params) => {
                    if (params.row.color === 'green') {
                        return clsx('green_row', {
                            selected: true ,
                        });
                    } else if (params.row.color === 'red') {
                        return clsx('red_row', {
                            selected: true ,
                        });
                    }
                }
             },
            { field: 'description', headerName: 'Descrição', flex: 0.3, filterType: 'string',
                renderCell: (props) => (<Tooltip title={props.row.tooltip}><Box>{props.row.description}</Box></Tooltip>),
                cellClassName: (params) => {
                    if (params.row.color === 'green') {
                        return clsx('green_row', {
                            selected: true ,
                        });
                    } else if (params.row.color === 'red') {
                        return clsx('red_row', {
                            selected: true ,
                        });
                    }
                }
             },
            { field: 'amount', headerName: 'Quantidade', flex: 0.2, filterType: 'number',
                renderCell: (props) => {
                   const result = compareAmount(props.row,state.logs)

                   return (
                    <Tooltip title={props.row.tooltip}>
                        <Box sx={{display:'flex',flexDirection:'row',gap:'0.5rem',marginLeft:'3rem'}} key={`amount${props.row.id}`}>
                            <Box>
                                {result[0]}
                            </Box>
                            
                            <Box sx={{color:result[2]}}>
                                {result[1]}
                            </Box>
                        </Box>
                    </Tooltip>
                   )

                },
                cellClassName: (params) => {
                    if (params.row.color === 'green') {
                        return clsx('green_row', {
                            selected: true ,
                        });
                    } else if (params.row.color === 'red') {
                        return clsx('red_row', {
                            selected: true ,
                        });
                    }
                }
                    
                
            },
            { field: 'balance', headerName: 'Balance', flex: 0.1, filterType: 'string',
                renderCell: (props) => (
                    <Tooltip title={props.row.tooltip}>
                        <Box>{(props.row.invoice === null ? props.row.invoice : props.row.invoice) - props.row.amount}</Box>
                    </Tooltip>
                ),
                cellClassName: (params) => {
                    if (params.row.color === 'green') {
                        return clsx('green_row', {
                            selected: true ,
                        });
                    } else if (params.row.color === 'red') {
                        return clsx('red_row', {
                            selected: true ,
                        });
                    }
                }
            },
            { field: 'price', headerName: 'Preço', flex: 0.2, filterType: 'number',
                renderCell: (props) => {
                    const result = comparePrice(props.row,state.logs)
 
                    return (
                        <Tooltip title={props.row.tooltip}>
                            <Box sx={{display:'flex',flexDirection:'row',gap:'0.5rem'}}>
                                <Box>
                                    ${result[0]}
                                </Box>
                                
                                <Box sx={{color:result[2]}}>
                                    {result[1]}
                                </Box>
                            </Box>
                        </Tooltip>
                    )
 
                 },
                 cellClassName: (params) => {
                    if (params.row.color === 'green') {
                        return clsx('green_row', {
                            selected: true ,
                        });
                    } else if (params.row.color === 'red') {
                        return clsx('red_row', {
                            selected: true ,
                        });
                    }
                }
            },
            {
                field: 'actions', headerName: 'Ações',filterable: false,
                renderCell: (props) => {
                    return (
                        <Box >
                           
                            { props.row.color !== 'red' ? permissions.change_productfile && <IconButton onClick={() => { setState({ editRow: props.row }) }}><EditIcon /></IconButton> : null}
                            { props.row.color !== 'red' ? permissions.delete_productfile && <IconButton onClick={() => { setState({ deleteRow: props.row }) }}><DeleteIcon /></IconButton> : null}
                        </Box>
                    )
                },
                cellClassName: (params) => {
                    if (params.row.color === 'green') {
                        return clsx('green_row', {
                            selected: true ,
                        });
                    } else if (params.row.color === 'red') {
                        return clsx('red_row', {
                            selected: true ,
                        });
                    }
                }
            }
        ]
    }

    useEffect(() => {
        deletedRow(state.rows,state.logs,setState)
        createColor(state.rows,state.logs)
        getLogs(data,setLoader,setState)
        loadData(setState, user.permissions.view_productfile, setLoader)
    }, [setLoader, user.permissions.view_productfile,state.rows])

    const [table, reloadTable] = TableHook()

    const reloadData = () => {
        loadData(setState, user.permissions.view_productfile, setLoader)
        reloadTable()
    }

    return(
        <>
         {state.deleteRow &&
                <ConfirmationDialog
                    content={`Deseja excluir o produto ${state.deleteRow.identifier}?`}
                    handleClose={() => setState({ deleteRow: null })}
                    onConfirm={() => submitDelete(state.deleteRow, setState, reloadData, setLoader)}
                />
         }
        {state.editRow &&
                <EditProducts
                    orderId={data.id}
                    data={state.editRow}
                    handleClose={() => setState({ editRow: null })}
                    reloadData={reloadData}
                />
        }

        {state.logRow && 

            <ConfirmationDialog
            content={`Deseja salvar o log do produto ?`}
            handleClose={() => setState({ logRow: null })}
            onConfirm={() => registerLog(data,setLoader,setState)}
            />

        }

        <ModalProduct
                title={
                    `Produtos`
                }
                dialogProps={{
                    sx:{'& .MuiPaper-root': {
                        maxWidth:'unset',
                        maxHeight:'unset',
                        width:'70%',
                        height:'70%',
                        margin:'0px'
                    }}
                }}
                logs={state.logs}
                handleClose={handleClose}
                content={
                    <>
                    <Box className='information-table'>
                            <Box className='user-table'>
                                <Table
                                    sx={{

                                        '& .green_row': {
                                            backgroundColor:'#1bff004a'
                                        },
                                        '& .red_row': {
                                            backgroundColor:'#ff00004a'
                                        }
                                    
                                    }}
                                    rows={state.rows}
                                    columns={useMemo(()=> columns(setState, user.permissions), [user.permissions])}
                                    pagination
                                    density='compact'
                                    slots={{
                                        addComponent: () => user.permissions.add_productfile ? setState({ editRow: {} }) : null,
                                        filterComponent: true,
                                        columnComponent: true
                                    }}
                                    toolbar
                                    getURL={`/documento_produto/${data.id}/products/`}
                                    updateRows={(rows) => setState({'rows': rows})}
                                    innerRef={table}
                                />
                            </Box>
                        </Box>
                        </>
                }

                action={<ColorButton onClick={() => { setState({ logRow: true }) }}>Salvar Lista</ColorButton>}
                />
        </>
    )
}

export default ProdutoDocumento;