import React, {useReducer, useEffect} from 'react'
import DefaultModal from '../Utils/DefaultModal';
import { lockedReducer } from '../Utils/reducers';
import { Box, Tooltip, IconButton} from '@mui/material'
import SendBox from './sendBox';
import clsx from 'clsx';
import { DataGrid } from '@mui/x-data-grid';
import ColorButton from '../Buttons/ColorButton';
import Typography from '@mui/material/Typography';
import ConfirmationDialog from '../Utils/ConfirmationDialog';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import SearchCodpro from './searchCodpro';
import { api } from '../../services/api';
import toast from 'react-hot-toast';

const validateSave = (data) => {

    const response = []
    if (data?.invoiceList.length === 0){
        response.push(<Box>Selecione um produto.</Box>)
    }
        
    if (response.length === 0)
        return ''

    return (response)
}



const InvoiceModal = ({data, setLoader, reloadData, setPreviousState}) => {

    const url = '/importacao/'

    const [state, setState] = useReducer(lockedReducer, {

        invoiceModal:null,
        deleteRow:null,
        invoiceList:data.invoice,
        deleteLine:null
        
    })

    const validData = validateSave(state)

   
    const deleteInvoice = async () => {
        let res = null
        setLoader(true)
            res = await api.patch(`${url}${data.id}/delete_invoice/`)
            setState({ deleteRow: null})
            setPreviousState({invoice:[]})
            reloadData()
        setLoader(false)
    
    }

    const handleClose = async () => {
        setState({invoiceModal:null})

       
        const resInvoice = await api.get(url + `${data.id}/consult_file/`)
        if (resInvoice.status === 200) {
            setState({invoiceList:resInvoice.data})
        }
      
    }

    const setNewList = (rows) => {
        setState({invoiceList:rows})
    }

    const deleteLine = () => {
        const row = state.deleteLine
        const newRows = state.invoiceList.filter(each =>  each.id !== row.id)
        setState({invoiceList:newRows,deleteLine:null})
        
    }

    const saveProduct = async (state,importacao,setLoader,setInvoice,setState) => {

        const body = {
            id_importacao: importacao.id,
            invoice: state.invoiceList
        }
    
        setLoader(true)
        const res = await api.post(`${url}save_file/`,body)
        setLoader(false)
        if (res.status === 200) {
            toast.success('Salvo com sucesso')
            setInvoice({invoice:state.invoiceList})
            setState({invoiceModal:null})
            return
        }
    
    }


    const columns = [
    
        {
            field: 'product',
            headerName: 'Codpro',
            flex: 0.1,
            cellClassName: () => {
                return clsx('default-app', {
                    selected: true ,
                });
            }

        },
        {
            field: 'description',
            headerName: 'Descrição',
            flex: 0.4,
            cellClassName: () => {
                return clsx('default-app', {
                    selected: true ,
                });
            },
            renderCell: (params) => (
                <Box sx={{display:'flex',flexDirection:'column', width:'100%',justifyContent:'start'}}>
                  
                    {params.value}
                </Box>
              ),

        },
        {
            field: 'filename',
            headerName: 'Documento',
            flex: 0.2,
            cellClassName: () => {
                return clsx('default-app', {
                    selected: true ,
                });
            }

        },
        {
            field: 'amount',
            headerName: 'Quantidade',
            flex: 0.2,
            cellClassName: () => {
                return clsx('default-app', {
                    selected: true ,
                });
            },
            renderCell: (params) => ( 
                <Box>
                    <SendBox quantity={params.value} row={params.row}/>
                </Box>
            ),
        },
        { field: 'balance', headerName: 'Balance', flex: 0.2, filterType: 'number',
            renderCell: (props) => (
                <Box sx={{marginLeft:'4.7rem'}}>{(props.row.invoice === null ? props.row.invoice : props.row.invoice) - props.row.amount}</Box>
            )
         },
        {
            field: 'price',
            headerName: 'Preço',
            flex: 0.1,
            cellClassName: () => {
                return clsx('default-app', {
                    selected: true ,
                });
            },
            renderCell: (params) => ( 
                <Box>
                   ${params.value} 
                </Box>
            ),

        },
        {
            field: 'trash',
            headerName: '',
            flex: 0.1,
            cellClassName: () => {
                return clsx('default-app', {
                    selected: true ,
                });
            },
            renderCell: (params) => ( 
                <Box>
                    <Tooltip title="Excluir linha"><IconButton onClick={()=> setState({deleteLine:params.row})}> <DeleteIcon/></IconButton></Tooltip>
                </Box>
            ),

        }

    ]

    useEffect(() => {
     
        try {
            
            const newRows = state.invoiceList?.sort((a, b) => a.description.localeCompare(b.description))
            setState({invoiceList:newRows})

        } catch (error) {
            
        }
      
        

    }, [])

   
    return (
        <>

        {state.deleteRow &&
                <ConfirmationDialog
                    content={`Deseja excluir o pedido invoice ?`}
                    handleClose={() => setState({ deleteRow: null })}
                    onConfirm={deleteInvoice}
                />
         }

        {state.deleteLine &&
                <ConfirmationDialog
                    content={`Deseja excluir essa linha ?`}
                    handleClose={() => setState({ deleteLine: null })}
                    onConfirm={deleteLine}
                />
         }

        { state.invoiceModal &&

            <DefaultModal
                    title={
                        `Produtos - Editando Invoice`
                    }
                    dialogProps={{
                        sx:{'& .MuiPaper-root': {
                            maxWidth:'unset',
                            maxHeight:'unset',
                            width: '80%',
                            height:'80%',
                            margin:'0px'
                        }}
                    }}
                    handleClose={handleClose}
                    content={
                        <>


                        <Box>
                            <SearchCodpro data={data} rowsB={state.invoiceList} invoiceList={state.invoiceList} setNewList={setNewList}/>
                        </Box>

                        <Box sx={{display:'flex',flexDirection:'column',gap:'3rem',height:'100%',overflow:'hidden'}}>

                            <Box sx={{flex:1, minHeight:'1rem'}} >  
                                <DataGrid
                                    sx={{
                                        '& .default-app.selected': {
                                            justifyContent:'center',
                                            alignItems:'center',
                                            fontSize:'14px'
                                        },
                                        height:"100%",
                                        '& .MuiDataGrid-columnHeaderTitleContainer': {
                                            justifyContent: 'center',
                                        },
                                        '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
                                            color:'black'
                                        }
                                    
                                    }}
                                    className='alterned_grid'
                                    rows={state.invoiceList}                                                                                                                                                                                                                                                                                                                                                                                                                       
                                    columns={columns}
                                    pagination={false}
                                    density='compact'
                                    pageSize={5}
                                    rowHeight={30}
                                />
                            </Box>

                        </Box>
                        
                        </>
                    }
                    action={
                        <>
                            <Tooltip placement="left" title={validData}>
                                <Box>
                                    <ColorButton
                                    onClick={() => saveProduct(state,data,setLoader,setPreviousState,setState)}
                                    disabled={validData}
                                    >Salvar
                                    </ColorButton>
                                </Box>
                            </Tooltip>
                        </>
                    }
                />

        }


        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                '& > :not(style)': {
                m: 1,
                width: '100%',
                height: '4rem',
                alignItems:'center',
                justifyContent:'space-evenly'
                },
            }}
        >

            <Paper elevation={1} sx={{display:'flex'}}>

                <Box>
                    <Typography>Pedido Invoice:</Typography>
                </Box>

                <Box sx={{width:'15rem'}}/>

                <Box>
                    <Tooltip title="Visualiza o invoice"><IconButton onClick={() => setState({invoiceModal:true})}><RemoveRedEyeIcon/></IconButton></Tooltip>
                    <Tooltip title="Deleta invoice"><IconButton onClick={() => setState({ deleteRow: true })}><DeleteIcon/></IconButton></Tooltip>
                </Box>
                
            </Paper>

        </Box>

        </>
    )
}

export default InvoiceModal;